<template>
  <div class="container">
    <div class="columns">
      <div class="column login-box card content">
        <div class="section">
          <h2 class="has-text-centered">
            City Of Philadelphia
          </h2>
          <h3 class="has-text-centered">
            IMPORTANT MESSAGE ABOUT VENDOR PAYMENTS
          </h3>

          <p>
            <a
              target="_blank"
              download
              href="/ACH-Vendor-Letter.pdf"
            >Download a PDF version</a> of this page.
          </p>

          <p>The City of Philadelphia encourages all vendors to enroll in its direct deposit electronic payment program. Instead of receiving paper checks, payments will be made electronically via Automated Clearing House (ACH) and deposited directly into an account designated by you at your financial institution. Enrolling in direct deposit payments supports the City’s ongoing efforts to become a more efficient and effective government, deliver enhanced services and timely payments to vendors, and provide for a sustainable environment.</p>

          <p>Benefits of direct deposit payments include:</p>
          <ul>
            <li>Safe, secure method of payment;</li>
            <li>Elimination of lost, misplaced or stolen checks, with no wait for replacement checks to be issued;</li>
            <li>Improvements in timing and certainty of payments, with no wait for paper checks to be delivered in the mail and then taken to a bank for deposit.</li>
          </ul>

          <p>
            To respond to concerns that direct deposit payments don’t provide sufficiently detailed information to vendors about their payments, the City has developed an improved, secure vendor payment website where vendors may see all detailed payment information normally printed on paper checks.  The information will include invoice numbers, applicable credit memos and amounts, adjustments and comments about the payments.  The website provides a status of all vendor payments approved by Finance, and has detailed contact information, by department, for vendors who have questions about their payments.
            The website is available at

            <a
              target="_blank"
              href="https://vendor-payments.phila.gov/login"
            >https://vendor-payments.phila.gov/login</a>.  Vendors must register to log in and access their information. Registration in the portal can only happen after the vendor receives an initial payment from the City. To enroll in ACH prior to receiving an initial payment from the City, vendors should reach out to their department contacts directly.
          </p>

          <p>For security purposes, the <strong>Direct Deposit (ACH) Enrollment and Change Form</strong> is only available upon logging in to the vendor payment website.  Choose ENROLL after logging in.  You are required to complete four sections:</p>

          <ol>
            <li>In the first box, choose whether this is a New Enrollment, Change to Existing Enrollment, or a Request to Cancel Enrollment;</li>
            <li>Payee/Company Information must be completed by the individual being paid or a company representative;</li>
            <li>Financial Institution Information must be completed by the individual who owns the bank account and/or an authorized company official;</li>
            <li>Authorization must be completed and signed by the individual who owns the bank account and/or an authorized company official.</li>
          </ol>

          <p>You may return the completed form in one of two ways:</p>
          <ul>
            <li>Mail to: City of Philadelphia-Office of the Director of Finance, 1401 John F Kennedy Blvd, Rm 1340, Philadelphia, PA 19102</li>
            <li>
              Email to: <a
                target="_blank"
                href="mailto:voucherverification@phila.gov"
              >voucherverification@phila.gov</a>
            </li>
          </ul>

          <p>Upon receipt of your completed form, it may take four to six weeks for the City to verify your information and for direct deposit of your payments to begin.</p>
          <p>We look forward to your participation and thank you for your cooperation as we strive to provide more convenient and secure methods around your receipt of City payments.</p>
          <p>If you have any questions, please do not hesitate to contact us at: </p>
          <p class="m-b-none">
            <strong>E-mail: </strong><a href="mailto:voucherverification@phila.gov">voucherverification@phila.gov</a>
          </p>
          <!-- <p class="m-b-none">
            <strong>Phone: </strong><a href="tel:+215-686-2674">215-686-2674</a>
          </p> -->
          <p><strong>Hours: </strong>Mon-Fri,  8 AM – 4 PM</p>

          <p class="has-text-centered">
            <strong><router-link :to="{ name: 'login' }">Go to login</router-link></strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ACHVendorLetter",
};
</script>
